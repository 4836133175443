/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Button, Container } from "reactstrap";
// core components

function IndexHeader() {
  let pageHeader = React.createRef();
  const [random, setRandom] = React.useState(0);
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      setRandom(randomNumberInRange(0, 1))
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  function randomNumberInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  return (
    <>
      <div className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/banner" + randomNumberInRange(0, 2) + ".jpg") + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand">
            <img
              alt="..."
              className="n-logo"
              src={require("assets/img/now-logo.png")}
            ></img>

            <h1 className="h1 text-sd">SORRBA</h1>
            <div className="text-sd">
              <h2>
                Durgotsab - 2024
              </h2>
              <h3>Celebrating <b>16 years</b> of togetherness</h3>
              <h4>
                <Button
                  className="btn btn-primary"
                  color="primary"
                  href="https://maps.app.goo.gl/SvrmPDpuk3JXgCu89"
                  target="_blank">
                  <i className="now-ui-icons location_pin"/> Our new Location
                </Button>
              </h4>
              <h4>
                <Button
                  className="btn btn-primary"
                  color="danger"
                  to="/dmPass"
                  id="upgrade-to-pro"
                  tag={Link}
                >
                  <b>&nbsp;Mahabhog Thali</b>
                </Button>
              </h4>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;